const checkHash = location => {
  let { hash } = location
  if (hash) {
    var element = document.querySelector(hash)
    element.scrollIntoView()
  }
  else {
    document.body.scrollIntoView()
  }
}

export const onRouteUpdate = ({ location }) => {
  checkHash(location)
}